import React, { lazy, Suspense, useState } from "react"
import { graphql, navigate } from "gatsby"
import { window } from "browser-monads"

import { TemplateContextProvider, getCity} from 'components/post/TemplateProvider'
import { PlacesContextProvider } from 'components/post/PlacesProvider'
import usePageLoading from 'components/utils/usePageLoading'
import useContextTheme from 'components/utils/useContextTheme'

// import "./postImpactArea.scss"
import SEO from "components/seo"
import Header from "components/header"
import Sections from "components/sections"
import Footer from "components/footer"
import WysiwygToHtml from "components/utils/wysiwygToHtml"

const PostImpactArea = lazy(() => import("components/post/postImpactArea"))
const ShareButtons = lazy(() => import("components/post/shareButtons"))

import "components/post/post.scss"

const PostTemplate = ({ data, ...props }) => {
  let postData = data.allWpNeighborhood.edges[0].node
  let templateData = data.allWpPage.edges[0].node

  const { neighborhood } = postData
  const { map } = neighborhood

  if (postData.neighborhood.photos && postData.neighborhood.photos.length > 0) {
    postData.featuredImage = { node : postData.neighborhood.photos[0] }
  }

  // TODO: Add vibe tags to vibe sets
  if (postData.neighborhood.vibes) postData.postDetails.vibes = postData.neighborhood.vibes.map(vibe => vibe.slug)

  //if (postData.postDetails.authorPhoto) authorPhoto = postData.postDetails.authorPhoto.mediaItemUrl
  if (postData.neighborhood.map) {
    // Create or merge place objects
    // TODO: Set global context
    // TODO: Make this a reusable util
    if (postData.postDetails.city == null || postData.postDetails.city.length == 0) {
      postData.postDetails.city = []

      postData.postDetails.city.push({
        cityDetails: {
          placemarker: postData.neighborhood.map
        }
      })
    } else {
      postData.postDetails.city[0].cityDetails.placemarker = postData.neighborhood.map
    }
  }

  const cityOveride = postData.postDetails && postData.postDetails.city
    ? postData.postDetails.city[0].slug
    : undefined

  // TODO: Redirect; Figure out why nested paths redirects like features/neighborhood don't work.
  if (props.path.includes('features/neighborhood')) {
    navigate(props.path.replace('/features/neighborhood/', '/neighborhoods/'))
  }

  let seo = postData.seo
  // TODO: Move this test to Wordpress so it can be overwritten there.
  let keywords = 'Neighborhood Map, Vibes, Events, Things to Do'

  let city = cityOveride?.replace('-', ' ')
  const cityFromPost = postData?.postDetails?.city
  const cityObject = cityFromPost
    ? cityFromPost[0]
    : getCity(city) // FIXME: this is async
  //console.log('TODO: set city ', city, cityObject);

  seo.title = `${postData.title} | ${keywords} | ${city} | Vibemap`
  seo.metaDesc = `Explore the vibes of ${postData.title}, ${cityOveride}`

  const [embedded, setEmbedded] = useState(false)
  const pageLoader = usePageLoading()
  const theme = useContextTheme()

  const sectionsFromPost = postData.sections.sections
  const sectionsFromTemplate = templateData.sections.sections

  const sectionsData = sectionsFromPost
    ? sectionsFromPost
    : sectionsFromTemplate

  const sectionDataOverride = sectionsData.map(data => {
    // Override section templates with Neighborhood
    if (data.fieldGroupName === "Page_Sections_Sections_SingCardsBlock") {
      data.singCards.heading = data.singCards.heading.replace('{neighborhood}', neighborhood?.name)
      data.singCards.subheading = data.singCards.subheading.replace('{neighborhood}', neighborhood?.name)
    }

    if (data.fieldGroupName === "Page_Sections_Sections_MapBlock") {
      data.heading = data.heading.replace('{neighborhood}', neighborhood?.name)
    }
  })

  const overrideQuery = {
    cities: cityOveride ? [cityOveride] : [],
    neighborhood: neighborhood,
    location: map,
    distance: neighborhood?.radius,
    zoom: neighborhood?.zoom
  }

  const pageClass = `page ${pageLoader} ${embedded ? 'embedded' : ''} ${theme ?  theme : ''}`
  return (
    <div className={pageClass}>
      <TemplateContextProvider
        city={cityObject}
        embeddedCallback={setEmbedded}
        hasMap={true}
        setURLParams={false}
        >
        <PlacesContextProvider>
          <SEO
            lang="en-US"
            title={postData.seo.title}
            data={postData.seo} />
          <main>
            <Header />
            {postData.postDetails &&
              <Suspense fallback={<span>...</span>}>
                <PostImpactArea
                  bannerLayout={postData.postDetails.bannerLayout}
                  showDate={false}
                  data={postData}
                  zoom={neighborhood?.map?.zoom ? parseInt(neighborhood?.map?.zoom) : undefined}
                />
              </Suspense>
            }
            <section className='body'>
              <div className="post-body">
                <div className="post-description">
                  <Suspense fallback={<span>...</span>}>
                    <ShareButtons
                      className="desktop-share-buttons"
                      url={window.location.href} />
                  </Suspense>

                  {postData.neighborhood.description &&
                    <div className="container visible">
                      <section>
                        <div className={'wp-block core-paragraph'}>
                          <WysiwygToHtml html={postData.neighborhood.description} />
                        </div>
                      </section>
                    </div>
                  }
                </div>

                {sectionsData &&
                  <Sections
                    data={sectionsData}
                    overrideQuery={overrideQuery}
                  />
                }
              </div>
            </section>
          </main>
          <Footer />
        </PlacesContextProvider>
      </TemplateContextProvider>
    </div>
  )
}
export default PostTemplate

export const neighborhoodQuery = graphql`
  query NeighborhoodQuery($id: Int!) {
    allWpPage(filter: {slug: {in: "neighborhood-template"}}) {
      edges {
        node {
          id
          title
          slug
          status
          date
          pageDetails {
            pageActivities {
              slug
            }
          }
          seo {
            title
            opengraphAuthor
            focuskw
            canonical
            opengraphDescription
            metaDesc
            metaKeywords
            opengraphImage {
              mediaItemUrl
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
          }
          sections {
            sections {
              ... on WpPage_Sections_Sections_AppStoreCallout {
                text
                appStoreUrl
                fieldGroupName
                googlePlayUrl
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED,
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
              ... on WpPage_Sections_Sections_VibesBlock {
                advancedOptions
                customVibes
                fieldGroupName
                heading
                style
                vibes {
                  name
                }
              }
              ... on WpPage_Sections_Sections_SingCardsBlock {
                fieldGroupName
                singCards {
                  fieldGroupName
                  heading
                  subheading
                  cssClass
                  style
                  vibemapSuggests
                  posts {
                    activityQuery {
                      slug
                    }
                    categoryQuery
                    distanceQuery
                    fieldGroupName
                    city
                    numOfPlaces
                    placeType
                    type
                    searchQuery
                    vibeQuery {
                      name
                      slug
                    }
                    subcategory
                    geoQuery {
                      latitude
                      longitude
                    }
                  }
                  showAll
                  showAllLink
                  showAllRelation
                  showAllText
                }
              }
              ... on WpPage_Sections_Sections_NeighborhoodCardsBlock {
                fieldGroupName
                neighborhoodCards {
                  automatic
                  fieldGroupName
                  heading
                  subHeading
                  vibeQuery {
                    name
                    slug
                  }
                }
              }
              ... on WpPage_Sections_Sections_MapBlock {
                fieldGroupName
                  customLocation
                  fitBoundToMarkers
                  heading
                  height
                  location {
                    ... on WpCity {
                      id
                      slug
                      cityDetails {
                        fieldGroupName
                        subheading
                        colors {
                          color1
                          color2
                        }
                        description
                        radius
                        vibe
                        placemarker {
                          latitude
                          longitude
                          city
                          country
                          state
                        }
                        photos {
                          link
                          localFile {
                            publicURL
                          }
                        }
                        vibeset {
                          ... on WpVibeset {
                            id
                            title
                            databaseId
                            vibesetDetails {
                              gradientImage {
                                mediaItemUrl
                              }
                            }
                          }
                        }
                        vibes {
                          slug
                          name
                        }
                      }
                    }
                    ... on WpNeighborhood {
                      id
                      neighborhood {
                        map {
                          latitude
                          longitude
                          zoom
                        }
                      }
                    }
                  }
                  map {
                    latitude
                    longitude
                    zoom
                  }
                  heatmap
                  directions
                  map3d
                  mapTheme
                  markerStyle
                  showMarkers
                  width
                  zoom
              }
              ... on WpPage_Sections_Sections_PostCardsBlock {
                automatic
                fieldGroupName
                heading
                subHeading
                vibeQuery {
                  name
                  slug
                }
              }
              ... on WpPage_Sections_Sections_TextWithImage {
                bodyText
                fieldGroupName
                heading
                layout
                textStyles
                style
                image {
                  altText
                  caption
                  localFile {
                    publicURL
                  }
                }
                vibeset {
                  ... on WpVibeset {
                    id
                    vibesetDetails {
                      gradientImage {
                        mediaItemUrl
                      }
                      gradientVideo {
                        mediaItemUrl
                      }
                    }
                  }
                }
                links {
                  externalUrl
                  fieldGroupName
                  forceDownload
                  linkText
                  linkType
                  email
                  formId
                  pageLink {
                    ... on WpPage {
                      id
                      uri
                    }
                    ... on WpPost {
                      id
                      uri
                    }
                  }
                  file {
                    id
                    localFile {
                      name
                      ext
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpNeighborhood(filter: { databaseId: { eq: $id } }) {
      edges {
        node {
          id
          title
          slug
          status
          date
          featuredImage {
            node {
              caption
              mediaItemUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
          tags {
            nodes {
              name
            }
          }
          neighborhood {
            fieldGroupName
            description
            instagramHashtag
            name
            boundary
            map {
              latitude
              longitude
              zoom
            }
            radius
            vibes {
              name
              slug
            }
            photos {
              link
              mediaItemUrl
            }
          }
          postDetails {
            bannerLayout
            showAuthorDetails
            authorBiography
            authorName
            authorBio
            authorPhoto {
              mediaItemUrl
            }
            customAuthor
            location {
              latitude
              longitude
              zoom
            }
            vibeset {
              ... on WpVibeset {
                id
                title
                databaseId
                vibesetDetails {
                  exploreLink
                  gradientImage {
                    mediaItemUrl
                  }
                  gradientVideo {
                    mediaItemUrl
                  }
                }
              }
            }
            city {
              ... on WpCity {
                id
                title
                uri
                slug
                databaseId
                cityDetails {
                  icon {
                    databaseId
                    localFile {
                      publicURL
                    }
                  }
                  placemarker {
                    latitude
                    longitude
                    zoom
                  }
                }
              }
            }
          }
          seo {
            title
            opengraphAuthor
            focuskw
            canonical
            opengraphDescription
            metaRobotsNofollow
            metaDesc
            metaKeywords
            opengraphImage {
              mediaItemUrl
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphSiteName
            opengraphTitle
            opengraphType
            opengraphUrl
          }
          sections {
            sections {
              ... on WpNeighborhood_Sections_Sections_CalloutBlock {
                bodyText
                fieldGroupName
                heading
              }
              ... on WpNeighborhood_Sections_Sections_FeaturedPost {
                fieldGroupName
                automatic
                heading
                featuredPost {
                  ... on WpPost {
                    id
                    databaseId
                    title
                    slug
                    uri
                    date
                    content
                    excerpt
                    featuredImage {
                      node {
                        caption
                        mediaItemUrl
                      }
                    }
                    author {
                      node {
                        name
                      }
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                    postDetails {
                      authorName
                      authorBio
                      customAuthor
                      vibeset {
                        ... on WpVibeset {
                          id
                          title
                          databaseId
                          vibesetDetails {
                            gradientImage {
                              localFile {
                                publicURL
                              }
                            }
                            gradientVideo {
                              localFile {
                                publicURL
                              }
                            }
                          }
                        }
                      }
                      city {
                        ... on WpCity {
                          id
                          title
                          uri
                          slug
                          databaseId
                        }
                      }
                    }
                  }
                }
                posts {
                  ... on WpPost {
                    id
                    databaseId
                    title
                    slug
                    uri
                    date
                    content
                    excerpt
                    featuredImage {
                      node {
                        caption
                        mediaItemUrl
                      }
                    }
                    author {
                      node {
                        name
                      }
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                    postDetails {
                      vibeset {
                        ... on WpVibeset {
                          id
                          title
                          databaseId
                          vibesetDetails {
                            gradientImage {
                              mediaItemUrl
                            }
                            gradientVideo {
                              mediaItemUrl
                            }
                          }
                        }
                      }
                      city {
                        ... on WpCity {
                          id
                          title
                          uri
                          slug
                          databaseId
                        }
                      }
                    }
                  }
                }
              }
              ... on WpNeighborhood_Sections_Sections_VibesBlock {
                fieldGroupName
                vibes {
                  name
                }
              }
              ... on WpNeighborhood_Sections_Sections_MapBlock {
                fieldGroupName
                customLocation
                fitBoundToMarkers
                heading
                height
                location {
                  ... on WpCity {
                    id
                    cityDetails {
                      placemarker {
                        latitude
                        longitude
                      }
                    }
                  }
                  ... on WpNeighborhood {
                    id
                    neighborhood {
                      map {
                        latitude
                        longitude
                      }
                    }
                  }
                }
                map {
                  latitude
                  longitude
                }
                directions
                map3d
                mapTheme
                markerStyle
                showMarkers
                heatmap
                width
                zoom
              }
              ... on WpNeighborhood_Sections_Sections_NeighborhoodCardsBlock {
                fieldGroupName
                neighborhoodCards {
                  automatic
                  city {
                    ... on WpCity {
                      id
                      slug
                      title
                      cityDetails {
                        placemarker {
                          latitude
                          longitude
                          city
                          country
                          state
                        }
                      }
                    }
                  }
                  fieldGroupName
                  heading
                  subHeading
                  vibeQuery {
                    name
                    slug
                  }
                }
              }
              ... on WpNeighborhood_Sections_Sections_PostCardsBlock {
                automatic
                fieldGroupName
                heading
                posts {
                  ... on WpPost {
                    id
                    content
                    title
                    link
                    featuredImage {
                      node {
                        caption
                        mediaItemUrl
                      }
                    }
                  }
                }
                subHeading
              }
              ... on WpNeighborhood_Sections_Sections_SingCardsBlock {
                fieldGroupName
                singCards {
                  fieldGroupName
                  heading
                  subheading
                  style
                  vibemapSuggests
                  posts {
                    eventId
                    fieldGroupName
                    placeId
                    type
                    title
                    city
                    subcategory
                    description
                    image
                    vibes
                    activityQuery {
                      slug
                    }
                    categoryQuery
                    distanceQuery
                    editorialCategoryQuery
                    geoQuery {
                      latitude
                      longitude
                    }
                    numOfPlaces
                    placeType
                    type
                    searchQuery
                    vibeQuery {
                      name
                      slug
                    }
                    vibes
                    post {
                      ... on WpPost {
                        id
                        databaseId
                        title
                        excerpt
                        uri
                        content
                        postDetails {
                          city {
                            ... on WpCity {
                              title
                            }
                          }
                        }
                        categories {
                          nodes {
                            name
                          }
                        }
                        featuredImage {
                          node {
                            caption
                            mediaItemUrl
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
